import React, { useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { getText } from '../../../i18n/Text';
import Product from '../../../types/Product';
import { useWidgetGroups } from '../../../widgets/useWidgetGroups';
import Widget from '../../../widgets/Widget';
import { WidgetPanel } from '../../../widgets/WidgetPanel/WidgetPanel';
import { Expander } from '../../Expander/Expander';
import { QuickviewDetailsLink } from '../components/QuickviewDetailsLink';
import { QuickviewTitle } from '../components/QuickviewTitle';

type Props = {
  product: Product;
  widgets: Widget[];
  seeMoreText: string;
  linkToDetail: string;
  children?: React.ReactNode;
};

export function ProductQuickview({
  product,
  widgets,
  seeMoreText,
  linkToDetail,
  children
}: Readonly<Props>) {
  const [collapsed, setCollapsed] = useState(true);

  const groupedWidgets = useWidgetGroups(widgets);

  const name = useMemo(() => {
    return getText(product.data.names);
  }, [product.data.names]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <section
      className="quickview quickview-product product-panel d-flex flex-column"
      onClick={toggle}
      aria-expanded={!collapsed}
    >
      <div className="quickview__main d-flex flex-row align-items-center">
        <div className="d-flex flex-column flex-grow-1 pe-md-4">
          {!isEmpty(groupedWidgets.primary) ? (
            groupedWidgets.primary.map((widget) => (
              <WidgetPanel
                key={widget.id}
                widget={widget}
                product={product}
                expanded={false}
                expandable={false}
              />
            ))
          ) : (
            <div>
              <QuickviewTitle>
                <span className="pe-1">({product.data.code})</span>
                <span>{name}</span>
              </QuickviewTitle>
              <div className="d-flex mt-2 column-gap--2_4rem flex-wrap">
                {children}
              </div>
            </div>
          )}
        </div>
        <Expander collapsed={collapsed} />
      </div>
      {
        // Note: the fontSize (and possibly other styles) need to be set inline
        // to prevent re-renders and other weird bugs
      }
      {!collapsed ? (
        <div
          style={{ fontSize: '1.5rem' }}
          className="quickview__extra"
        >
          <div className="quickview__extra__summary flex-grow-1 d-flex flex-column">
            {!isEmpty(groupedWidgets.summary) ? (
              <div className="flex-grow-1">
                {groupedWidgets.summary.map((widget) => (
                  <WidgetPanel
                    key={widget.id}
                    widget={widget}
                    product={product}
                    expanded={false}
                    expandable={false}
                  />
                ))}
              </div>
            ) : null}
            <QuickviewDetailsLink
              linkToDetail={linkToDetail}
              seeMoreText={seeMoreText}
            />
          </div>
          {!isEmpty(groupedWidgets.detail) ? (
            <div className="quickview__extra__details">
              {groupedWidgets.detail.map((widget) => (
                <WidgetPanel
                  key={widget.id}
                  widget={widget}
                  product={product}
                  expanded={false}
                  expandable={false}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </section>
  );
}
